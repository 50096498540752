<script lang="ts" setup>
import { required, minLength } from '@vuelidate/validators';
import { LoginFlowType } from '~/graphql/generated/graphql';
import type { LoginState, LoginStepProps } from '~/types/login';
import type { FormResult } from '@year13/ui';
import { computed, ref } from 'vue';
import type { UIPinCodeType } from '~/types/otpPinCode';
import { CombinedError } from '@urql/core';

const props = defineProps<LoginStepProps>();

const loginState = defineModel<LoginState>({ required: true });

const emit = defineEmits<{
  stepComplete: [];
  reset: [];
}>();

const { verifyLoginCode } = useAuth();
const { t } = useI18n();
const analytics = useAnalyticsService();

const result = ref<FormResult | null>(null);
const hasUnverifiedError = ref<boolean>(false);

const verifyCode = async (doneLoading: () => void) => {
  result.value = null;
  const { flow, error } = await verifyLoginCode({
    roleType: props.roleType,
    code: convertToString(loginState.value.otpCode),
    email: loginState.value.email,
  });

  doneLoading();

  if (!flow) {
    if (error instanceof CombinedError && findGraphqlErrorWithCode(error, 'UNVERIFIED_EMAIL_ACCOUNT')) {
      hasUnverifiedError.value = true;
    }

    result.value = {
      variant: 'error',
      message: typeof error === 'string' ? error : getFirstErrorMessageFromCombinedErrors(t, error),
    };
    return;
  }

  // We handle this flow in this component
  if (flow === LoginFlowType.InvalidCode) {
    analytics.track(EVENTS.LOGIN_OTP_SUBMITTED, { correct: 'no' });
    result.value = { variant: 'error', message: t('general.auth.invalidOtpCode') };
    return;
  }

  analytics.track(EVENTS.LOGIN_OTP_SUBMITTED, { correct: 'yes' });

  loginState.value.flow = flow;
  emit('stepComplete');
};

const { requestLoginCode: _requestLoginCode } = useAuth();
const resendLabel = computed(() =>
  loginState.value.hasPrefilled ? t('general.formFields.cantLogin.label') : t('general.formFields.notGettingEmail.label'),
);
const isResent = ref<boolean>(false);
const resendLoading = ref<boolean>(false);
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
const inputRef = ref<UIPinCodeType | undefined>(undefined);

const requestLoginCode = async (resetValidationErrors: () => void) => {
  resendLoading.value = true;
  resetValidationErrors();
  result.value = null;
  const { error } = await _requestLoginCode({ email: loginState.value.email, roleType: props.roleType });
  resendLoading.value = false;

  if (error) {
    return;
  }

  inputRef.value?.fullReset();
  isResent.value = true;

  analytics.track(EVENTS.LOGIN_OTP_RESENT);
};

const resentMessage = computed(() => {
  return t('general.auth.resentOtpCode', { email: loginState.value.email });
});
</script>

<template>
  <div>
    <UIForm
      :buttonVariant="{
        variant: 'primary',
        isFullwidth: false,
        position: 'center',
        disabled: loginState.otpCode.length < 6,
      }"
      :hideSubmitButton="resendLoading || hasUnverifiedError"
      :submitButtonText="$t('general.formFields.submit.label')"
      data-testid="auth-otp-step"
      @submit="verifyCode"
    >
      <template #default="{ loading, resetValidationErrors }">
        <div class="flex flex-col items-center justify-center w-full my-10">
          <UIHeading customClasses="font-semibold" size="h1" styleSize="h4">
            {{ $t('general.auth.enterOtpHeader') }}
          </UIHeading>
          <template v-if="loading || resendLoading">
            <UIHeading class="text-center" customClasses="font-normal mt-3 text-grey-500" size="h4" styleSize="h6">
              <span>
                {{ loading ? $t('general.auth.verifyingOtpHelp') : $t('general.auth.resendingOtpHelp') }}
              </span>
            </UIHeading>
          </template>
          <template v-else>
            <UIHeading class="text-center" customClasses="font-normal text-black mt-3" size="h2" styleSize="h6">
              <UIHtml :html="$t('general.auth.enterOtpHelp', { email: loginState.email })" />
            </UIHeading>
            <UIButton
              :text="$t('general.auth.changeEmailButton')"
              class="mt-2"
              customClasses="text-secondary-500"
              type="button"
              variant="no-style"
              @click="emit('reset')"
            />
          </template>
        </div>
        <UIPinCode
          ref="inputRef"
          v-model="loginState.otpCode"
          :enteredCodeScreenReader="$t('general.formFields.otpPinCode.srText', { code: loginState.otpCode })"
          :codeDigitInitialLabel="$t('general.formFields.otpPinCode.initialDigit', { index: '[[index]]' })"
          :codeDigitLabel="$t('general.formFields.otpPinCode.digit', { index: '[[index]]' })"
          :disabled="loading || resendLoading"
          :rules="{ required, minLength: minLength(6) }"
          focusOnMounted
          nextFocusOnSubmitButton
        />
        <!-- add custom alert here -->
        <UITransitionFadeIn>
          <UIAlertMessageText v-if="result" :message="result.message" :variant="result.variant" class="mt-3" />
        </UITransitionFadeIn>
        <div v-if="!hasUnverifiedError" :class="{ 'opacity-0': loading }" class="flex flex-col items-center justify-center mt-3">
          <template v-if="isResent">
            <p class="text-center">{{ resentMessage }}</p>
          </template>
          <template v-else>
            <p :class="{ 'text-opacity-0': resendLoading }" class="text-black">{{ resendLabel }}</p>
            <UIButton
              :disabled="loading"
              :loading="resendLoading"
              :text="$t('general.formFields.resendCode.label')"
              class="mt-1"
              customClasses="text-secondary-500"
              type="button"
              variant="no-style"
              @click="requestLoginCode(resetValidationErrors)"
            />
          </template>
        </div>
      </template>
    </UIForm>
  </div>
</template>
