<script lang="ts" setup>
import { useVuelidate } from '@vuelidate/core';
import { computed, unref } from 'vue';
import type { InputAttributes } from '~ui/types/inputAttributes';
import type { ValidationProp } from '~ui/types/validationProp';

interface Props extends InputAttributes {
  errorMessages?: string[];
  rules: ValidationProp;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  errorMessages: () => [],
  disabled: false,
});

const value = defineModel<boolean>({ required: true });

const validationKey = useId();

const validation = useVuelidate({ [validationKey]: props.rules }, { [validationKey]: value });

const _errorMessages = computed(() => {
  const errors = validation.value.$errors.map((e) => unref(e.$message));
  return [...props.errorMessages, ...errors];
});

const onBlur = () => {
  validation.value.$touch();
};
</script>

<template>
  <UIInputCheckbox v-model="value" :label="label" :errorMessages="_errorMessages" :disabled="disabled" :hideLabel="hideLabel" :required="required" @blur="onBlur">
    <template v-if="$slots.customLabel" #customLabel>
      <slot name="customLabel" />
    </template>
  </UIInputCheckbox>
</template>
