<script setup lang="ts">
import { required, maxLength, sameAs, helpers } from '@vuelidate/validators';
import type { LoginState, LoginStepProps } from '~/types/login';
import { useMutation, useQuery } from '@urql/vue';
import { signUpStudentMutation, getSiteCountry } from '~/graphql/documents/core';
import type { FormResult } from '@year13/ui';
import { ref } from 'vue';

defineProps<LoginStepProps>();

const loginState = defineModel<LoginState>({ required: true });

const emit = defineEmits<{ stepComplete: [] }>();
const { globalTeacherRole } = useAuth();
const { canAuthedTeacherRegister } = useGhostStudent();

interface SignUpFields {
  firstName: string;
  lastName: string;
  stateId: string | null;
  classYear?: number | null;
}

const makeFormState = (firstName: string = '', lastName: string = '', stateId: string | null = null) => {
  return { firstName, lastName, classYear: undefined, stateId };
};

const getInitialFormState = () => {
  if (canAuthedTeacherRegister.value) {
    return makeFormState(
      globalTeacherRole.value?.firstName || '',
      globalTeacherRole.value?.lastName || '',
      globalTeacherRole.value?.school?.stateId || null,
    );
  }

  const ghostFirstName = useCookie('ghostFirstName');
  return makeFormState(ghostFirstName.value || '');
};

const formState = ref<SignUpFields>(getInitialFormState());
const { t } = useI18n();
// early fetch for US states
const { clusterDomain, isManageDomain } = useDomainConfig();
const { data, fetching, stale } = useQuery({ query: getSiteCountry, variables: { domain: clusterDomain } });
const states = computed(() => data.value?.core.site.publicSiteConfig?.country.states || []);
const usStates = computed(() => states.value.map((state) => ({ label: state.name, value: state.id })));
const isTermsAndConditionApproved = ref<boolean>(false);
const isAgeApproved = ref<boolean>(false);
const configStore = useConfigStore();
const hasAgeConsentCheck = configStore.cluster?.hasAgeConsentCheck;

const { isManageReferred } = useCrossSitesConfig();

const result = ref<FormResult | null>(null);

const { executeMutation: _signUpStudent } = useMutation(signUpStudentMutation);

const register = async (doneLoading: () => void) => {
  if (!formState.value.stateId) {
    return;
  }
  const { classYear } = formState.value;
  const res = await _signUpStudent({
    input: {
      ...formState.value,
      classYear,
      email: loginState.value.email,
      stateId: formState.value.stateId,
      isManageReferred: isManageDomain ? undefined : !!isManageReferred.value,
    },
  });

  doneLoading();
  if (res.error) {
    result.value = { variant: 'error', message: getFirstErrorMessageFromCombinedErrors(t, res.error) };
    return;
  }

  if (res.data?.core.user.signUpStudent) {
    emit('stepComplete');
  }
};

const tncRequiredRule = helpers.withMessage(t('general.auth.termsAndCondition.approvalRequired'), sameAs(true));
const ageConsentRule = helpers.withMessage(t('general.auth.minAgeConsent.approvalRequired'), sameAs(true));

const fetchingStates = computed(() => fetching.value || stale.value);
</script>

<template>
  <UIForm
    :submitButtonText="$t('general.auth.signUp')"
    :buttonVariant="{
      variant: 'primary',
      isFullwidth: false,
      position: 'center',
    }"
    data-testid="auth-register-step"
    @submit="register"
  >
    <template #default="{ loading }">
      <UIFormInput
        v-model="formState.firstName"
        :rules="{ required, maxLength: maxLength(255) }"
        :required="true"
        :label="$t('general.formFields.firstName.label')"
        :placeholder="$t('general.formFields.firstName.placeholder')"
        autocomplete="given-name"
        :disabled="loading"
      />
      <UIFormInput
        v-model="formState.lastName"
        :rules="{ required, maxLength: maxLength(255) }"
        :required="true"
        :label="$t('general.formFields.lastName.label')"
        :placeholder="$t('general.formFields.lastName.placeholder')"
        autocomplete="family-name"
        :disabled="loading"
      />
      <CoreStateSelect
        v-model="formState.stateId"
        :label="$t('general.formFields.state.label')"
        :placeholder="$t('general.formFields.state.placeholder')"
        :options="usStates"
        :disabled="loading"
        :rules="{ required }"
        :required="true"
        :initialLoading="fetchingStates"
      />
      <CoreClassYearSelect v-model="formState.classYear" :loading="loading" :required="true" />
      <UIFormCheckbox v-model="isTermsAndConditionApproved" label="" :rules="{ checked: tncRequiredRule }" :required="true" :disabled="loading">
        <template #customLabel>
          <span>
            {{ $t('general.auth.termsAndCondition.label') }}
            <UIButton
              :text="$t('general.auth.termsAndCondition.link')"
              to="/terms-condition"
              variant="no-style"
              customClasses="underline"
              :isExternalLink="true"
            />
          </span>
        </template>
      </UIFormCheckbox>
      <UIFormCheckbox
        v-if="hasAgeConsentCheck"
        v-model="isAgeApproved"
        :label="$t('general.auth.minAgeConsent.label')"
        :rules="{ required, checked: ageConsentRule }"
        :required="true"
        :disabled="loading"
        data-testid="consent-minimum-age"
      />
      <UITransitionFadeIn>
        <UIAlertMessageText v-if="result" :message="result.message" :variant="result.variant" class="mt-3" />
      </UITransitionFadeIn>
    </template>
  </UIForm>
</template>
