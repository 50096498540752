<script setup lang="ts">
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { computed } from 'vue';
import type { InputAttributes } from '~ui/types/inputAttributes';

interface Props extends InputAttributes {
  errorMessages?: string[];
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  errorMessages: () => [],
  hideLabel: false,
});

const value = defineModel<boolean>({ default: false });
const emit = defineEmits<{ blur: [event: FocusEvent] }>();
const elementId = useId();
const variantClasses = computed<string>(() => {
  return twMerge(
    cva('w-5 h-5 align-middle accent-primary-500 border border-primary-500 rounded-md p-2 cursor-pointer', {
      variants: {
        disabled: {
          true: 'bg-grey-300 border-grey-400 acccent-grey-400',
        },
        hasError: {
          true: 'border-red-500',
        },
      },
    })({
      disabled: props.disabled,
      hasError: props.errorMessages.length > 0,
    }),
  );
});

const textClasses = computed<string>(() => {
  return twMerge(
    cva('ml-3 cursor-pointer', {
      variants: {
        disabled: {
          true: 'text-grey-300',
          false: 'text-primary-500',
        },
        hasError: {
          true: 'text-red-500',
        },
      },
    })({
      disabled: props.disabled,
      hasError: props.errorMessages.length > 0,
    }),
  );
});

const checkboxContainer = ref<HTMLElement | undefined>(undefined);
const onFocusOut = (event: FocusEvent) => {
  if (!checkboxContainer.value?.contains(<HTMLElement>event.relatedTarget)) {
    emit('blur', event);
  }
};
</script>

<template>
  <div>
    <div ref="checkboxContainer" class="flex items-center" @focusout="onFocusOut">
      <input :id="elementId" v-model="value" type="checkbox" :class="variantClasses" :aria-required="required" :disabled="disabled" />
      <label v-if="!hideLabel" :class="textClasses" :for="elementId">
        <slot v-if="$slots.customLabel" name="customLabel" />
        <span v-else>{{ label }}</span>
        <sup v-if="required" class="text-red-500">*</sup>
      </label>
    </div>
    <UIInputErrors :errorMessages="errorMessages" />
  </div>
</template>
