<script lang="ts" setup>
const emit = defineEmits<{ stepComplete: [] }>();

const getStarted = () => {
  emit('stepComplete');
};
</script>

<template>
  <div class="flex justify-center mt-5 flex-col items-center text-center space-y-8" data-testid="auth-signup-interest">
    <p>{{ $t('general.auth.interestAdded.paragraph1') }}</p>
    <p>{{ $t('general.auth.interestAdded.paragraph2') }}</p>
    <UIButton :text="$t('general.auth.interestAdded.actionButton')" @click="getStarted" />
  </div>
</template>
