<script lang="ts" setup>
import { maxLength, required, sameAs, helpers } from '@vuelidate/validators';
import { ref } from 'vue';
import type { FormResult } from '@year13/ui';
import { useMutation, useQuery } from '@urql/vue';
import { getSiteCountry, signUpTeacherMutation } from '~/graphql/documents/core';
import type { LoginState } from '~/types/login';

const loginState = defineModel<LoginState>({ required: true });
const emit = defineEmits<{ stepComplete: [] }>();
const { clusterDomain } = useDomainConfig();

const { data, fetching, stale } = useQuery({ query: getSiteCountry, variables: { domain: clusterDomain } });

const states = computed(() => data.value?.core.site.publicSiteConfig?.country.states || []);
const usStates = computed(() => states.value.map((state) => ({ label: state.name, value: state.id })));
const isTermsAndConditionApproved = ref<boolean>(false);
const isAgeApproved = ref<boolean>(false);

interface SignUpFields {
  firstName: string;
  lastName: string;
  stateId: string | null;
}

const getInitialFormState = () => {
  return {
    firstName: '',
    lastName: '',
    stateId: null,
  };
};

const formState = ref<SignUpFields>(getInitialFormState());
const result = ref<FormResult | null>(null);

const { t } = useI18n();

const { executeMutation: _signUpTeacher } = useMutation(signUpTeacherMutation);
const register = async (doneLoading: () => void) => {
  if (!formState.value.stateId) {
    return;
  }

  const res = await _signUpTeacher({
    input: {
      ...formState.value,
      email: loginState.value.email,
      stateId: formState.value.stateId,
    },
  });

  doneLoading();
  if (res.error) {
    result.value = { variant: 'error', message: getFirstErrorMessageFromCombinedErrors(t, res.error) };
    return;
  }

  if (res.data?.core.user.signUpTeacher) {
    emit('stepComplete');
  }
};
const tncRequiredRule = helpers.withMessage(t('general.auth.termsAndCondition.approvalRequired'), sameAs(true));
const ageConsentRule = helpers.withMessage(t('general.auth.minAgeConsent.approvalRequired'), sameAs(true));

const fetchingStates = computed(() => fetching.value || stale.value);
</script>

<template>
  <UIForm
    :submitButtonText="$t('general.auth.signUp')"
    :buttonVariant="{
      variant: 'primary',
      isFullwidth: false,
      position: 'center',
    }"
    data-testid="auth-register-step"
    @submit="register"
  >
    <template #default="{ loading }">
      <UIFormInput
        v-model="formState.firstName"
        :rules="{ required, maxLength: maxLength(255) }"
        :required="true"
        :label="$t('general.formFields.firstName.label')"
        :placeholder="$t('general.formFields.firstName.placeholder')"
        autocomplete="given-name"
        :disabled="loading"
      />
      <UIFormInput
        v-model="formState.lastName"
        :rules="{ required, maxLength: maxLength(255) }"
        :required="true"
        :label="$t('general.formFields.lastName.label')"
        :placeholder="$t('general.formFields.lastName.placeholder')"
        autocomplete="family-name"
        :disabled="loading"
      />
      <CoreStateSelect
        v-model="formState.stateId"
        :label="$t('general.formFields.state.label')"
        :placeholder="$t('general.formFields.state.placeholder')"
        :options="usStates"
        :disabled="loading"
        :rules="{ required }"
        :required="true"
        :initialLoading="fetchingStates"
      />
      <UIFormCheckbox v-model="isTermsAndConditionApproved" label="" :rules="{ checked: tncRequiredRule }" :required="true" :disabled="loading">
        <template #customLabel>
          <span>
            {{ $t('general.auth.termsAndCondition.label') }}
            <UIButton
              :text="$t('general.auth.termsAndCondition.link')"
              to="/terms-condition"
              variant="no-style"
              customClasses="underline"
              :isExternalLink="true"
            />
          </span>
        </template>
      </UIFormCheckbox>
      <UIFormCheckbox
        v-model="isAgeApproved"
        :label="$t('general.auth.minAgeConsent.label')"
        :rules="{ required, checked: ageConsentRule }"
        :required="true"
        :disabled="loading"
        data-testid="consent-minimum-age"
      />
    </template>
  </UIForm>
</template>
